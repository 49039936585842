<template>
  <div>
    <div ref="container" class="container">
      <!-- Headline component -->
      <div class="">
        <CtPromo
          :header1="$t('vaa.electionsSubHeadline')"
          :header2="$t('vaa.electionsHeadline')"
          size="large"
          icon-type="promotions"
          :small="true"
          :cta-border="true"
        />

        <div
          class="max-w-xl mx-auto -mt-8 leading-tight disclaimer text-center"
        >
          <i18n-t keypath="vaa.howDoesTheVaaWork" tag="small" scope="global">
            <template #one>
              <NuxtLink
                :to="{
                  name: 'article',
                  params: { id: $t('vaa.howDoesTheVaaWorkLink1') },
                }"
              >
                {{ $t('vaa.howDoesTheVaaWorkLink1Text') }}
              </NuxtLink>
            </template>
            <template v-if="$te('vaa.howDoesTheVaaWorkLink2')" #two>
              <NuxtLink
                :to="{
                  name: 'article',
                  params: { id: $t('vaa.howDoesTheVaaWorkLink2') },
                }"
              >
                {{ $t('vaa.howDoesTheVaaWorkLink2Text') }}
              </NuxtLink>
            </template>
          </i18n-t>
        </div>
      </div>

      <div class="mt-15 flex flex-col space-y-10">
        <div v-for="election in elections" :key="election.ID">
          <CtElectionCard
            v-if="election.valgomat"
            :header="
              election && election.ID === 5
                ? 'Valgtest'
                : $t('vaa.theCandidateTest')
            "
            :cta="election.FrontendUrlPrefix"
            :cta-label="$t('vaa.takeTheTest')"
            :valgomat="election.valgomat"
            size="large"
            :election="election"
            :theme="config.public.vaa?.[election.FrontendUrlPrefix]?.theme"
          />
        </div>
      </div>

      <!-- Swiper -->
      <div>
        <template v-if="inactiveElections && inactiveElections.length">
          <h2 class="text-center mt-20 text-4xl uppercase font-light">
            Tidligere valg
          </h2>
          <LayoutSwipeList
            :slides-per-view="2"
            :container="false"
            class="swiper mt-5 mb-15 test-slider"
          >
            <SwiperSlide
              v-for="election in inactiveElections"
              :key="election.ID"
            >
              <CtElectionCard
                v-if="election.valgomat"
                :header="
                  election && election.ID === 5
                    ? 'Valgtest'
                    : $t('vaa.theCandidateTest')
                "
                :cta="election.FrontendUrlPrefix"
                :cta-label="$t('vaa.takeTheTest')"
                :valgomat="election.valgomat"
                size="small"
                :election="election"
                :theme="config.public.vaa?.[election.FrontendUrlPrefix]?.theme"
              />
            </SwiperSlide>
          </LayoutSwipeList>
        </template>
      </div>

      <!-- Promo component -->
      <div class="">
        <CtPromo
          class="my-8 sm:my-16"
          header1=""
          :header2="$t('vaa.electionsAltingetMakesVaas')"
          disclaimer=""
          icon-type="kandidattest"
          :small="true"
          :icon="config.public.site.identifier === 'altinget_dk'"
          :cta-label="$t('vaa.aboutTheVaa')"
          :cta="$t('vaa.aboutTheVaaLink')"
          :cta-border="true"
          :second-cta-label="$t('vaa.interestedInAPartnership')"
          :second-cta="$t('vaa.interestedInAPartnershipLink')"
          :second-cta-border="true"
        />
      </div>

      <!-- Newsletter component -->
      <div v-if="!config.public.site.electionSlug">
        <NewsletterSignup />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()

const vaaStore = useVaaStore()
await vaaStore.loadAll()

const elections = computed(() => vaaStore.getActiveElections)
const inactiveElections = computed(() => vaaStore.getInactiveElections)

useServerSeoMeta({
  title: nuxtApp.$t('vaa.electionsPageMetaTitle'),
  ogTitle: nuxtApp.$t('vaa.electionsPageMetaTitle'),
  description: nuxtApp.$t('vaa.electionsPageMetaDescription'),
  ogDescription: nuxtApp.$t('vaa.electionsPageMetaDescription'),
})
</script>

<style lang="scss" scoped>
.test-slider {
  .swiper-slide {
    min-width: 50%;
  }
}
</style>
